import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button
} from "reactstrap"

import DataPeseta from "./DataPeserta"
// import LogSimulasi from "./LogSimulasi"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"
import JawabanUTT from "./JawabanUTT"
import JawabanEsaiUTT from "./JawabanEsaiUTT"
import JawabanMatriksUTT from "./JawabanMatriksUTT"
import JawabanBipolarUTT from "./JawabanBipolarUTT"
import JawabanMengurutkanUTT from "./JawabanMengurutkanUTT"


function MonitoringUTT(props) {
  //meta title
  document.title = "Simulai | BPKP"

  const [customActiveTab, setcustomActiveTab] = useState("1")
  const { idSimulasi } = useParams()
  const { idPeserta } = useParams()
  const { idJadwal } = useParams()
  const { simulasi } = useParams()
  const [dataPeserta, SetDataPeserta] = useState()
  const [dataLogs, setDataLogs] = useState()
  const [loading, setLoading] = useState()
  const [loading2, setLoading2] = useState()
  const [loading3, setLoading3] = useState()
  const [lastUpdate, setLastUpdate] = useState()
  const [firstUpdate, setFirstUpdate] = useState()
  const [jawaban, setJawaban] = useState()
  const [jawabanUrut, setJawabanUrut] = useState()
  const [tipe, setTipe] = useState()
  const [time, setTime] = useState()
  const [status, setStatus] = useState()
  const [dataJawaban, setDataJawaban] = useState()
  const [idLayout, setIdLayout] = useState();
  const [soal, setSoal] = useState()
  const [studiKasus, setStudiKasus] = useState()
  const [skenario, setSkenario] = useState()
  const [listSosmed, setListSosmed] = useState();
  const [statusTimer, setStatusTimer] = useState(null);
  const [dataMatriks, setDataMatriks] = useState();
  const [durasi, setdurasi] = useState("")


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  //data peserta
  useEffect(() => {
    setLoading(true)
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Detail-simulasi?id_peserta=" +
      idPeserta +
      "&id_simulasi=" +
      idSimulasi +
      `&id_jadwal=` +
      idJadwal

    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        SetDataPeserta(responseData.data[0].peserta)
        setLoading(false)
        Swal.close()
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        }).then(() => {
          window.history.back()
        })
      })
  }, [])

  function groupBySoal(data) {
    return data.reduce((acc, current) => {
      const soalId = current.soal.id_msoal;
      if (!acc[soalId]) {
        acc[soalId] = {
          soal: current.soal,
          jawaban: current.jawaban,
          jawaban_utt: []
        };
      }
      acc[soalId].jawaban_utt.push({
        id_jawaban_utt: current.id_jawaban_utt,
        ju_jawaban: status == 'selesai' ? current.ju_jawaban : current.pilihan,
        ju_value: status == 'selesai' ? current.ju_value : current.value,
        ju_skala: current.ju_skala,
        ju_id_indikator_jawaban: current.ju_id_indikator_jawaban,
        ju_id_msoal_jawaban: status == 'selesai' ? current.ju_id_msoal_jawaban : current.id_msoal_jawaban,
        // more utt details...
      });
      return acc;
    }, {});
  }

  //jawaban peserta
  useEffect(() => {
    const fetchData = () => {
      setLoading2(true);
      const baseURLGet =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        `jawaban/jawaban-utt?ja_id_peserta=` +
        idPeserta +
        `&ssst_id_sub_test=` +
        idSimulasi +
        `&id_mjadwal=` +
        idJadwal;

      fetch(baseURLGet, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token_2"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData.meta.code === 200) {
            if (responseData.data) {
              const newData = groupBySoal(responseData.data.data);
              setDataMatriks(newData);
              setDataJawaban(responseData.data.data);
              setIdLayout(responseData.data.subtest.mst_idlayout);
              setStatus(responseData.data.status.ssst_status);
              setSoal(responseData.data.grubsoal);
              setStudiKasus(responseData.data.case);
              setSkenario(responseData.data.skenario);
              setListSosmed(responseData.data.paketsosmed);
              console.log(responseData.data.paketsosmed);
              Swal.close();
            }
          } else {
            console.error("Unexpected response:", responseData);
          }
        })
        .catch(error => {
          console.error("Fetch error:", error);
        })
        .finally(() => {
          setLoading2(false); // Always set loading to false, even if an error occurs
        });
    };

    fetchData(); // Initial fetch

    const intervalId = setInterval(fetchData, 30000); // Fetch every 30 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);



  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptionsGet = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/status-timer-utt?ja_id_peserta=` +
      idPeserta +
      `&ssst_id_sub_test=` +
      idSimulasi +
      `&id_mjadwal=` +
      idJadwal;
    fetch(baseURLGet, requestOptionsGet)
      .then(response => response.json())
      .then(result => {
        if (result.meta.code == 200) {
          setStatusTimer(result.data.timerStatus.status)
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }, [])

  //log jawaban
  useEffect(() => {
    setLoading3(true)
    const fetchData = () => {
      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token_2")
      )

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }

      const baseURLlogs =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "monitoring/log?id_peserta=" +
        idPeserta +
        "&id_simulasi=" +
        idSimulasi +
        "&id_jadwal=" +
        idJadwal

      fetch(baseURLlogs, requestOptions)
        .then(response => response.json())
        .then(result => {
          setDataLogs(result.data[0])
          const dataCount = result?.data[0]?.length || 0;
          setFirstUpdate(result.data[0][dataCount - 1].updated_at)
          setTime(result.data[0][0].timer)
          Swal.close()
          setLoading3(false)
        })
        .catch(error => {
          setLoading3(false)
        })
    }

    // Fetch data immediately
    fetchData()
  }, [])

  const handleUpdateTimer = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptionsGet = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/update-timer-utt?ja_id_peserta=` +
      idPeserta +
      `&ssst_id_sub_test=` +
      idSimulasi +
      `&id_mjadwal=` +
      idJadwal;
    fetch(baseURLGet, requestOptionsGet)
      .then(response => response.json())
      .then(result => {
        if (result.meta.code == 200) {
          setStatusTimer(result.data.timerStatus.status)
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }

  const handleExport = async () => {
    const url = process.env.REACT_APP_BACKEND_URL_VERSION + `jawaban/export-jawaban?id_subtest=${idSimulasi}&id_jadwal=${idJadwal}&nip=${dataPeserta?.nip}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("access_token_2")}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = urlBlob;
      a.download = 'jawabanUTT_' + dataPeserta?.nip + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(urlBlob);
      document.body.removeChild(a);
    } catch (error) {
      console.error('There was an error exporting the data:', error);
    }
  };

  const refilteredData = Array.isArray(dataJawaban) ? dataJawaban.reduce((acc, curr) => {
    const soalId = curr.ju_id_msoal;

    // Find if this soal already exists in the result array
    let soalIndex = acc.findIndex(item => item.id_msoal === soalId);

    if (soalIndex === -1) {
      // If the soal does not exist, add it
      acc.push({
        id_msoal: soalId,
        soal: curr.soal,
        jawaban: curr.jawaban,
        jawaban_utt: [
          {
            id_jawaban_utt: curr.id_jawaban_utt,
            ju_nip: curr.ju_nip,
            ju_id_mjadwal: curr.ju_id_mjadwal,
            ju_id_alattest: curr.ju_id_alattest,
            ju_id_subtest: curr.ju_id_subtest,
            ju_jawaban: curr.ju_jawaban,
            ju_no_urutsoal: curr.ju_no_urutsoal,
            ju_value: curr.ju_value,
            ju_skala: curr.ju_skala,
            ju_id_jasesi: curr.ju_id_jasesi,
            ju_id_indikator_jawaban: curr.ju_id_indikator_jawaban,
            ju_id_msoal_jawaban: curr.ju_id_msoal_jawaban
          }
        ]
      });
    } else {
      // If the soal exists, add the jawaban_utt to the existing soal
      acc[soalIndex].jawaban_utt.push({
        id_jawaban_utt: curr.id_jawaban_utt,
        ju_nip: curr.ju_nip,
        ju_id_mjadwal: curr.ju_id_mjadwal,
        ju_id_alattest: curr.ju_id_alattest,
        ju_id_subtest: curr.ju_id_subtest,
        ju_jawaban: curr.ju_jawaban,
        ju_no_urutsoal: curr.ju_no_urutsoal,
        ju_value: curr.ju_value,
        ju_skala: curr.ju_skala,
        ju_id_jasesi: curr.ju_id_jasesi,
        ju_id_indikator_jawaban: curr.ju_id_indikator_jawaban,
        ju_id_msoal_jawaban: curr.ju_id_msoal_jawaban
      });
    }

    return acc;
  }, []) : [];  // Default to an empty array if data is not an array

  const addDurasi = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menambah durasi ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token_2")
        )

        var requestOptionsGet = {
          method: "PUT",
          headers: myHeaders,
          redirect: "follow",
        }
        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          `jawaban/add-timer-utt?ja_id_peserta=` +
          idPeserta +
          `&ssst_id_sub_test=` +
          idSimulasi +
          `&id_mjadwal=` +
          idJadwal +
          `&time=` +
          durasi;
        fetch(baseURLGet, requestOptionsGet)
          .then(response => response.json())
          .then(result => {
            Swal.close() // Close loading state
            Swal.fire("Success", "Durasi telah ditambahkan", "success")
            console.log(result)
            window.location.reload()
          })
          .catch(error => {
            console.log("error", error)
          })
      }
    })
  }

  const handleInputChange = event => {
    setdurasi(event.target.value)
  }

  useEffect(() => {
    console.log('status : ', status)
  }, [status])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem={"Monitoring " + simulasi}
          />
          <Row>
            <Col xl={9}>
              {/* {status === 'selesai' && (
              )} */}
              <>
                <label>Tambah Waktu</label>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="text-start"
                >
                  <input
                    type="number"
                    min="0"
                    max="999"
                    className="form-control"
                    value={durasi}
                    onChange={handleInputChange}
                    style={{ marginRight: "10px", width: "300px" }}
                  />
                  <button className="btn btn-primary" onClick={() => addDurasi()}>
                    Tambah
                  </button>
                </div>
                <pre>
                  <i>tambahkan waktu dalam tipe menit</i>
                </pre>
              </>
            </Col>
            <Col lg={3}>
              <Button
                className="float-end btn btn-secondary m-1"
                onClick={handleExport}
              >
                Export
              </Button>
              {statusTimer === false && (
                <Button
                  className="float-end btn btn-primary m-1"
                  onClick={handleUpdateTimer}
                >
                  Lanjutkan
                </Button>
              )}
            </Col>
            <Col lg="12">
              {loading === true ? (
                <div className=" ms-2 mt-2 fw-bold">
                  <div
                    role="status"
                    className="spinner-border text-dark spinner-border-sm"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>{" "}
                  Loading...
                </div>
              ) : (
                <>

                  <DataPeseta data={dataPeserta} />
                </>
              )}
            </Col>
          </Row>
          {dataJawaban && dataJawaban.length > 0 && (
            <>
              {idLayout && (idLayout === 1 || idLayout === 11 || idLayout === 13) ? (
                <JawabanUTT jawaban={dataJawaban} status={status} case={studiKasus} listSosmed={listSosmed} skenario={skenario} soal={soal} />
              ) : (idLayout === 7 || idLayout === 8) ? (
                <JawabanEsaiUTT jawaban={dataJawaban} status={status} case={studiKasus} listSosmed={listSosmed} skenario={skenario} soal={soal} />
              ) : (idLayout === 6 || idLayout === 14) ? (
                <JawabanMatriksUTT jawaban={refilteredData} status={status} soal={soal} case={studiKasus} listSosmed={listSosmed} skenario={skenario} />
              ) : idLayout === 5 ? (
                <JawabanBipolarUTT jawaban={dataJawaban} status={status} case={studiKasus} listSosmed={listSosmed} skenario={skenario} soal={soal} />
              ) : idLayout === 10 && (
                <JawabanMengurutkanUTT jawaban={dataJawaban} status={status} case={studiKasus} listSosmed={listSosmed} skenario={skenario} soal={soal} />
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MonitoringUTT
